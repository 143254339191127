import ResumePDF from '../assets/Ishaan_Tiwari_Resume.pdf'
import { Timeline } from 'flowbite-react';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'
import { useState } from 'react';

export default function CustomTimeline() {
    return (
      <section id='resume'>
        <div className="bg-white text-black py-8">
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
            <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 mb-20">
              <h2 class="text-3xl tracking-tight text-blue-500 sm:text-4xl font-serif italic">My Résumé</h2>
              <p class="mt-2 text-lg leading-8 text-gray-600 mb-4">An overview of my professional journey so far.</p>
              <a
                href={ResumePDF} target="_blank" rel="noopener noreferrer"
                className="bg-transparent mr-auto hover:bg-blue-500 hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-blue-500 hover:border-transparent"
              >
                Download PDF
              </a>
            </div>
            <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
              <div className="container mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden p-10 h-full">
                  <Component />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       
  )
}

function Component() {
  return (
    <Timeline>
      <Bits />
      <Ceeri />
      <Bny />
      <Amazon />
      <Quince />
    </Timeline>
  );
}

function Bits() {
  const [bits, setBits] = useState(true)
  const toggleBits = () => setBits(!bits);
  return (
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>August 2017 - August 2021</Timeline.Time>
        <Timeline.Title>Birla Institute of Technology & Science, Pilani</Timeline.Title>
        <Timeline.Body className='text-gray-600'>B.E. Electrical & Electronics Engineer</Timeline.Body>
        {bits ?
          <div className='flex gap-2 items-center' onClick={toggleBits}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleBits}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          bits &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              CGPA: 8.65/10 (First Division)
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Two-time recipient of the Institute Merit Scholarship, awarded to the top 2% of each batch every semester.</li>
              <li>Awarded a transfer within the same tier, in recognition of my exceptionally meritorious academic performance. Such transfers were granted to a total of 10 people out of 1,200.</li>
              <li>Head, Department of Sponsorship and Marketing for BOSM - Led a three-tiered team of 30 people working towards raising sponsorship for the BITS Open Sports Meet (BOSM), BITS Pilani's annual international sports festival.</li>
              <li>Committee of Students for Sports Activities - Part of an eight-member elected body liaising between BITS Pilani and its students on all sporting activities, and responsible for taking all administrative decisions for BOSM.</li>
              <li>Worked under Dr. Syed Mohammad Zafaruddin - Developing Optical Wireless Communication systems that work in the visible spectra using Deep Learning, overcoming the channel defects of turbulence, fogginess, or combinations of both.</li>
              {/* <li>Interned at the Central Electronics Engineering Reseach Institute. Worked under Dr. Suriya Prakash to evaluate the impact of modifying various components within Single Image Depth Estimation convolutional neural networks using Python and TensorFlow.</li> */}
              {/* <li>Interned at the Bank of New York Mellon. Automated run-time capture for the Data Design team’s pipelines in Pentaho, and optimized overall run-time for select pipelines by updating the SQL queries and creating indices over frequently read table.</li> */}
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Amazon() {
  const [amazon, setAmazon] = useState(false)
  const toggleAmazon = () => setAmazon(!amazon);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>July 2021 - October 2023</Timeline.Time>
        <Timeline.Title>Amazon</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Software Development Engineer</Timeline.Body>
        {amazon ?
          <div className='flex gap-2 items-center' onClick={toggleAmazon}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleAmazon}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          amazon &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              Worked with the Global Mile, Distribution Center Tech, and Amazon Fulfillment Tech teams to build logistics solutions that scaled to millions of transactions per second.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Designed and implemented the back-end for Stow Planner, a tool offering real-time visibility into available space of a warehouse, using OpenSearch, GraphQL, Amazon Kinesis Streams, AWS Lambda, Amazon S3, and DynamoDB.</li>
              <li>Implemented parallel processing to reduce the page load time of Stow Planner by 70% compared to its pilot launch.</li>
              <li>Developed accounting validation systems overseeing the migration of inventory valued at over 10 million USD.</li>
              <li>Enhanced the data querying logic of the Outbound tool, which tracks the schedule and inventory details for all planned shipments of a warehouse. This enabled a theoretical 50-fold increase in the tool’s operational capacity.</li>
              <li>Implemented distributed rate limiting and validations, achieving a 60% reduction in CPU usage. Successfully blocked bot traffic to safeguard against server outages during peak loads, ensuring uninterrupted service availability.</li>
              <li>Implemented the front-end for Problem Solve, an inventory anomaly reporting tool, using React and Typescript.</li>
              <li>Awarded individual performance recognition awards by all of my organisations at Amazon for my Operational Excellence.</li>
              <li>Developed a data warehousing proof of concept, reducing provisioned read capacities on our DynamoDB by 30%.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Quince() {
  const [quince, setQuince] = useState(false)
  const toggleQuince = () => setQuince(!quince);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>October 2023 - Present</Timeline.Time>
        <Timeline.Title>Quince</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Software Development Engineer - II</Timeline.Body>
        {quince ?
          <div className='flex gap-2 items-center' onClick={toggleQuince}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleQuince}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          quince &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              Working with the Storefront Extension team, building and maintaining the Systems for our customer facing website.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Designed and implemented the Wish List Sharing feature using AES, Python, AWS Lambda, and Amazon Cognito.</li>
              <li>Implemented the Estimated Delivery Date feature, contributing to an estimated 5% increase in overall revenue WoW.</li>
              <li>Implemented the Product Review System using Kotlin-Spring, HTML, Amazon S3, MySQL and Elasticsearch.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Ceeri() {
  const [ceeri, setCeeri] = useState(false)
  const toggleCeeri = () => setCeeri(!ceeri);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>May 2019 - July 2019</Timeline.Time>
        <Timeline.Title>CSIR - Central Electronics Engineering Research Institute (CEERI)</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Intern</Timeline.Body>
        {ceeri ?
          <div className='flex gap-2 items-center' onClick={toggleCeeri}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleCeeri}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          ceeri &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              CEERI is a premier institution established by the Government of India for the advancement of R&D in the field of Electronics.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
            <li>Worked under Dr. Suriya Prakash to evaluate the impact of modifying various components within Single Image Depth Estimation convolutional neural networks using Python and TensorFlow.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Bny() {
  const [bny, setBny] = useState(false)
  const toggleBny = () => setBny(!bny);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>August 2020 - December 2020</Timeline.Time>
        <Timeline.Title>Bank of New York (BNY) Mellon</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Intern</Timeline.Body>
        {bny ?
          <div className='flex gap-2 items-center' onClick={toggleBny}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleBny}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          bny &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              Worked as a part of the Data Design team at BNY Mellon, partnering closely with business teams to ensure data assets are designed and developed with an eye towards extensibility and future use.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Interned at the Bank of New York Mellon. Automated run-time capture for the Data Design team’s pipelines in Pentaho, and optimized overall run-time for select pipelines by updating the SQL queries and creating indices over frequently read table.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

