import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import DP from "../assets/DP.jpg"
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const navigation = [
  { name: 'Portfolio', href: '/#portfolio' },
  { name: 'Résumé', href: '/#resume' },
  { name: 'About', href: '/about' }
]

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  console.log(isModalOpen)

  return (
    
    <div>
        {isModalOpen && <ContactModal onClose={toggleModal}/>}
        <header className="lg:fixed absolute inset-x-0 top-0 z-50 bg-white shadow-md">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                {/* Logo */}
                <a href="/">
                    <p className="text-blue-500 font-serif italic text-2xl font-bold">
                        i.
                    </p>
                </a>

                {/* Hamburger Button (Mobile) */}
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <div className='hidden lg:flex lg:gap-x-12'>
                    {/* Desktop Navigation Menu */}
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>

                    <div className='border-l-2 border-gray-500'/>

                    {/* Contact */}
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <button className="leading-6 text-blue-500 font-bold" onClick={toggleModal}>
                            Contact me <span aria-hidden="true">&rarr;</span>
                        </button>
                    </div>
                </div>
            </nav>


            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    
                    <div className="flex items-center justify-between">
                        {/* Logo */}
                        <a href="/">
                            <p className="text-blue-500 font-serif italic text-2xl font-bold">
                                i.
                            </p>
                        </a>

                        {/* Close Button */}
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            
                            {/* Mobile Navigation Menu */}
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            
                            {/* Mobile Contact Button */}
                            <div className="py-6">
                            <a className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-blue-500 hover:bg-gray-50" href='https://www.linkedin.com/in/theishaantiwari' target="_blank">
                                Contact me
                            </a>

                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>

    </div>

  )
}

function ContactModal({ onClose }) {
    const handleCancel = () => onClose();
  
    return (
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <img src={DP} className='rounded-full h-24 w-24'/>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Hey There!</h3>
                    <div className="mt-2 flex flex-col gap-2">
                    <p className="text-sm text-gray-600">Thank you for exploring my portfolio! Your interest means a lot to me. Feel free to reach out using one of the links below. Looking forward to hearing from you!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 flex justify-between">
                <button
                  onClick={handleCancel}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
                <div className='flex gap-2'>
                    <a
                        className="inline-flex justify-center rounded-md px-3 py-2 bg-white border-[#d93025] text-[#d93025] shadow-md hover:bg-[#d93025] hover:text-white border"
                        href='mailto:ishaanmtiwari@gmail.com' target="_blank"
                    >
                        <EnvelopeIcon className='h-6 w-6'/>
                    </a>
                    <a
                        className="inline-flex justify-center rounded-md px-3 py-2 bg-white border-[#0077B5] text-[#0077B5] shadow-md hover:bg-[#0077B5] hover:text-white border"
                        href='https://www.linkedin.com/in/theishaantiwari' target="_blank"
                    >
                        <FontAwesomeIcon icon={faLinkedinIn} className="h-6 w-6" />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  